import React from "react";
import Navbar from "../../../../components/navbar/navbar.component";
import { LinkbuildingService } from "../../../../api/linkbuilding-service";
import { IProject } from "../../../../interfaces/project.interface";
import { useProjectStore, useLinkbuildingCampaignStore } from "../../../../store";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ILinkPlacement, ILinkPlacementKeyword, IWebsite } from "../../models/linkplacement";
import DisplayCampaignComponent from "../../components/display-campaign.component";
import DisplayWebsiteComponent from "../../components/display-website.component";
import { ApiResponse } from "../../../../api/base/api-response";
import { PaginatedResponse } from "../../../../api/base/paginated-response";
import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
import { ConfirmDialog } from "primereact/confirmdialog";
import EditLinkplacementDialogComponent from "../../components/edit-linkplacement-dialog.component";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { dropDownEditor, textEditor } from "../../components/data-form-helpers";
import WebsiteListSelectie from "../../components/websiteListSelectie";
import { useGoogleLogin } from "@react-oauth/google";
import GoogleLogo from '../../../../assets/images/Google__G__logo.png';
import "./linkbuilding-linkplacement.styles.scss";
import { IContact } from "../../models/contact";

const LinkbuildingLinkplacement = () => {

    const attributionOptions = [
        { label: 'Follow', value: 'Follow' },
        { label: 'NoFollow', value: 'NoFollow' },
        { label: 'Sponsored', value: 'Sponsored' }
    ];

    const [linkOptions, setLinkOptions] = React.useState<{ label: string; value: string }[]>([]);

    const linkbuildingService = new LinkbuildingService();
    const params: any = useParams();
    const navigate = useNavigate();

    // Data stores
    const selectedProject: IProject = useProjectStore(store => store.selectedProject);
    const linkbuildingCampaignStore = useLinkbuildingCampaignStore();

    // State
    const [loadingLinkplacement, setloadingLinkplacement] = React.useState(false);
    const [editMode, setEditMode] = React.useState(false);
    const [createMode, setCreateMode] = React.useState(false);

    // List of API data
    const [linkplacement, setLinkplacement] = React.useState<ILinkPlacement>();

    //Only created mode
    const [selectedWebsite, setSelectedWebsite] = React.useState<IWebsite>();
    const [websites, setWebsites] = React.useState<IWebsite[]>([]);

    const [contacts, setContacts] = React.useState<IContact[]>([]);
    const [selectedContact, setSelectedContact] = React.useState<IContact | null>(null);

    const [accessToken, setAccessToken] = React.useState<string | null>(
        sessionStorage.getItem('googleAccessToken') || null
    );
    const [emailSubject, setEmailSubject] = React.useState('');
    const [templates, setTemplates] = React.useState<Array<{ name: string; placeholders: string[] }>>([]);
    const [selectedTemplate, setSelectedTemplate] = React.useState('');
    const [replacements, setReplacements] = React.useState({});

    const login = useGoogleLogin({
        flow: 'implicit',
        scope: 'https://www.googleapis.com/auth/gmail.compose',
        onSuccess: tokenResponse => {
            setAccessToken(tokenResponse.access_token);
            sessionStorage.setItem('googleAccessToken', tokenResponse.access_token);
            alert('Google authentication successful! We have an access token.');
        },
        onError: () => {
            alert('Authentication failed. Please try again.');
        },
    });

    const formatNameFromEmail = (email: string): string => {
        const namePart = email.split('@')[0];
        const [firstName, lastName] = namePart.split('.');
        const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);
        if (!lastName) {
            return `${capitalize(firstName)}`;
        }
        return `${capitalize(firstName)} ${capitalize(lastName)}`;
    };

    const draftEmail = async () => {
        if (!accessToken) {
            alert('Please authenticate with Google (Gmail scope) first.');
            return;
        }
        try {
            const userEmail = localStorage.getItem('devote_user_name');

            if (!userEmail) {
                throw new Error('User email not found in localStorage');
            }
            if (!selectedContact) {
                throw new Error('No contact selected');
            }

            const naam = formatNameFromEmail(userEmail);
            const finalReplacements = {
                ...replacements,
                naam,
                naam_blogger: selectedContact.name
            };
            const response = await linkbuildingService.draftEmail(
                accessToken, {
                template: selectedTemplate,
                replacements: finalReplacements,
                email: selectedContact.email,
                subject: emailSubject
            });

            if (response.ok) {
                alert('Email drafted successfully!');
            } else {
                setAccessToken(null);
                alert('Failed to draft email. Please re-authenticate and try again.');
            }
        } catch (error) {
            alert('Failed to draft email due to a network error.');
        }
    };

    React.useEffect(() => {
        const fetchTemplates = async () => {
            try {
                const response = await (await linkbuildingService.getEmailTemplates()).json();
                setTemplates(response);
                if (response.length > 0) {
                    setSelectedTemplate(response[0].name);
                    const initialReplacements = response[0].placeholders.reduce((acc: any, ph: any) => ({
                        ...acc,
                        [ph]: ''
                    }), {});
                    setReplacements(initialReplacements);
                }
            } catch (error) {
                console.error('Error fetching templates:', error);
            }
        };
        fetchTemplates();
    }, []);

    const fetchContacts = (websiteId: string) => {
        linkbuildingService.getWebsiteContacts(selectedProject.id, websiteId).then((apiResponse: ApiResponse<PaginatedResponse<IContact[]>>) => {
            if (!apiResponse.hasError()) {
                apiResponse.getBody().then((body) => {
                    setContacts(body.data);
                });
            }
        });
    }

    React.useEffect(() => {
        if (linkplacement) {
            fetchContacts(linkplacement.website.id);
        }
    }, []);

    // BUTTON ACTIONS
    const btnClickLinkplacementUpdate = () => {
        setEditMode(true);
    }
    const btnClickNewKeyword = () => {
        let keyword = {
            "keywordText": "New keyword",
            "attribution": "Follow",
            // "campaignPageLinkId": "New campaign page link id"
        } as ILinkPlacementKeyword;
        linkbuildingService.createLinkplacementKeyword(selectedProject.id, linkplacement?.id as string, keyword).then((apiResponse: ApiResponse<ILinkPlacementKeyword>) => {
            if (apiResponse.hasError()) {
                console.log(apiResponse.getError());
            }
            apiResponse.getBody().then((body: ILinkPlacementKeyword) => {
                let item = {
                    ...linkplacement,
                } as ILinkPlacement;
                item.keywords.push(body);
                setLinkplacement(item);
            });
        });
    }

    const loadNewLinkplacementAfterChange = () => {
        loadLinkplacement(linkplacement?.id);
    }

    // API REQUESTS

    const newCreateMode = () => {
        linkbuildingService.getWebsites().then((apiResponse: ApiResponse<PaginatedResponse<IWebsite[]>>) => {
            if (!apiResponse.hasError()) {
                apiResponse.getBody().then((body: PaginatedResponse<IWebsite[]>) => {
                    const resp = body.data;
                    setWebsites(resp);
                });
            }
        });
        setCreateMode(true);
    }

    const loadLinkplacement = (id) => {
        let tmp_linkplacement = linkbuildingCampaignStore.selectedCampaign.linkplacements.filter(e => e.id === id)[0];
        linkbuildingService.getCampaignLinkplacement(selectedProject.id, tmp_linkplacement.campaignId, tmp_linkplacement.id).then((apiResponse: ApiResponse<ILinkPlacement>) => {
            if (apiResponse.hasError()) {
                console.log(apiResponse.getError());
            }
            apiResponse.getBody().then((body: ILinkPlacement) => {
                tmp_linkplacement = body;
            });
        });
        fetchContacts(tmp_linkplacement.websiteId);


        linkbuildingService.getWebsite(selectedProject.id, tmp_linkplacement.websiteId).then((apiResponse: ApiResponse<IWebsite>) => {
            if (apiResponse.hasError()) {
                console.log(apiResponse.getError());
            }
            apiResponse.getBody().then((body: IWebsite) => {
                tmp_linkplacement.lastUpdated = new Date(tmp_linkplacement.lastUpdated);
                tmp_linkplacement.publishDate = new Date(tmp_linkplacement.publishDate);
                const item = {
                    ...tmp_linkplacement,
                    website: body
                }
                setLinkplacement(item as ILinkPlacement);

                linkbuildingService.getLinkplacementKeywords(selectedProject.id, tmp_linkplacement.id).then((apiResponse: ApiResponse<PaginatedResponse<ILinkPlacementKeyword[]>>) => {
                    if (apiResponse.hasError()) {
                        console.log(apiResponse.getError());
                    }
                    apiResponse.getBody().then((body: PaginatedResponse<ILinkPlacementKeyword[]>) => {

                        const refined_item = {
                            ...item,
                            keywords: body.data
                        }
                        setLinkplacement(refined_item as ILinkPlacement);
                    });
                });
            });
        });
        let items: { label: string; value: string }[] = [];
        for (let i = 0; i < linkbuildingCampaignStore.selectedCampaign.campaignPageLinks.length; i++) {
            items.push({
                label: linkbuildingCampaignStore.selectedCampaign.campaignPageLinks[i].pageLinkText,
                value: linkbuildingCampaignStore.selectedCampaign.campaignPageLinks[i].id
            });
        }
        setLinkOptions(items);
    }

    const updateCampaignPageLink = (row) => {
        let item = {
            id: row.newData.id,
            attribution: row.newData.attribution,
            keywordText: row.newData.keywordText,
            campaignPageLinks_Id: row.newData.campaignPageLinks_Id,
            linkplacements_Id: linkplacement?.id,
            soft_deleted: 0,
        }
        linkbuildingService.updateLinkplacementKeyword(selectedProject.id, linkplacement?.id as string, item as unknown as ILinkPlacementKeyword).then((apiResponse: ApiResponse<ILinkPlacementKeyword>) => {
            if (apiResponse.hasError()) {
                console.log(apiResponse.getError());
            }
            apiResponse.getBody().then((body: ILinkPlacementKeyword) => {

                let notUpdatedKeywords = linkplacement?.keywords.filter((keyword) => {
                    if (keyword.id != body.id) {
                        return body;
                    }
                });
                if (notUpdatedKeywords == null) {
                    notUpdatedKeywords = [];
                }
                let updatedKeyWord = body as ILinkPlacementKeyword;
                notUpdatedKeywords.push(updatedKeyWord);
                let updatedLinkplacement = {
                    ...linkplacement,
                    keywords: notUpdatedKeywords
                } as ILinkPlacement;
                setLinkplacement(updatedLinkplacement);
            });
        });
    }

    React.useEffect(() => {
        const storedToken = sessionStorage.getItem('googleAccessToken');
        if (storedToken) {
            setAccessToken(storedToken);
        }

        if (params.id && params.id !== 'new') {
            loadLinkplacement(params.id);

        } else {
            if (linkplacement?.id !== params.id) {
                setLinkplacement({ "id": params.id, "publishDate": new Date() } as ILinkPlacement);
                newCreateMode();
            }
        }
    }, [selectedWebsite]);

    const updateWebsite = (website: any) => {
        const temp = website.value as IWebsite;
        setSelectedWebsite(temp);
    }

    // FEATURES HEADERS
    const keywordsHeader = (
        <div className="table-header">
            <h5 className="mx-0 my-1">Keywords</h5>
            <Button
                icon="pi pi-plus"
                className="p-button p-button-success mr-2"
                onClick={() => btnClickNewKeyword()}
                value="Create a new project" />
        </div>
    )

    // FEATURES BODY
    const linkplacementKeyWordUrlBody = (row) => {
        let url = linkbuildingCampaignStore.selectedCampaign.customer_DomainUrl + "/" + row.pageLinkText;
        return (
            <div>
                <Link to={`${url}`}>{url}</Link>
            </div>
        )
    }

    const linkplacementKeyWordBodyTemplate = (rowData: ILinkPlacementKeyword) => {
        return (
            <div className="d-flex justify-content-center">
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger"
                    onClick={() => { linkbuildingService.deleteLinkplacementKeyword(selectedProject.id, linkplacement?.id as string, rowData.id as string) }} />

            </div>
        )
    }

    return (
        <>
            <Navbar activeTool={'Linkbuilding'} activePage={'Home'} />
            <div className="layout-application-window" style={{ padding: '0px 40px' }} >

                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '25px', marginTop: '25px' }}>
                    <DisplayCampaignComponent campaign={linkbuildingCampaignStore.selectedCampaign} />
                </div>
                {createMode ?
                    <div>
                        <Card style={{ marginBottom: '25px' }}>
                            {selectedWebsite == null ? <h1>Please select a website</h1> : null}

                            <WebsiteListSelectie setWebsiteSelected={updateWebsite} rowsPerPageOptionInput={[5, 10, 15, 25, 50]} partnerId={undefined} />
                        </Card>

                        {selectedWebsite != null ?
                            <div>
                                <DisplayWebsiteComponent website={selectedWebsite} />
                                {/* <Button onClick={() => navigate(`/linkbuilding/websites?website=${linkplacement?.websiteId}`)} label="View website" className="p-button-primary" style={{ width: '100%' }} />  */}
                            </div>
                            : null}
                    </div>
                    :
                    <div>
                        {linkplacement?.website == null ?
                            <h1>Loading website...</h1>
                            :
                            <div>
                                <DisplayWebsiteComponent website={linkplacement?.website} />
                                {/* <Button onClick={() => navigate(`/linkbuilding/websites?website=${linkplacement?.websiteId}`)} label="View website" className="p-button-primary" style={{ width: '100%' }} />  */}
                            </div>
                        }
                    </div>
                }
                {(linkplacement?.website != null && linkplacement?.website != undefined) || selectedWebsite != null ?
                    <div className="linkplacement-row">
                        <Card className="linkplacement-general" style={{ marginBottom: '25px', marginTop: '25px' }}>
                            <div className="list-blocks">
                                <div className="sub-item">
                                    <p className="sub-item-key">Url</p>
                                    <p className="sub-item-value">{linkplacement?.placedOnUrl}</p>
                                </div>

                                <div className="sub-item">
                                    <p className="sub-item-key">Comments</p>
                                    <p className="sub-item-value">{linkplacement?.comment}</p>
                                </div>

                                <div className="sub-item">
                                    <p className="sub-item-key">Price</p>
                                    <p className="sub-item-value">{linkplacement?.price}</p>
                                </div>
                                <div className="sub-item">
                                    <p className="sub-item-key">Publish date</p>
                                    <p className="sub-item-value">{linkplacement?.publishDate?.toDateString()}</p>
                                </div>
                                <div className="sub-item">
                                    <p className="sub-item-key">Status</p>
                                    <p className="sub-item-value">{linkplacement?.status}</p>
                                </div>
                            </div>
                            <Button onClick={() => btnClickLinkplacementUpdate()} label="Edit" severity="success" />
                        </Card>
                        <Card className="linkbuidling-acties" style={{ marginBottom: '25px', marginTop: '25px' }}>
                            <h1>Email drafting</h1>
                            <div className="email-form">
                                <div className="input-group">
                                    <label>Email Template:</label>
                                    <select
                                        value={selectedTemplate}
                                        onChange={(e) => {
                                            const newTemplate = e.target.value;
                                            setSelectedTemplate(newTemplate);
                                            const template: any = templates.find(t => t.name === newTemplate);
                                            setReplacements(
                                                template?.placeholders.reduce((acc: any, ph: any) => ({ ...acc, [ph]: '' }), {}) || {}
                                            );
                                        }}
                                    >
                                        {templates.map(template => (
                                            <option key={template.name} value={template.name}>
                                                {template.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="input-group">
                                    <label>Select Contact:</label>
                                    <select
                                        onChange={(e) => {
                                            const contactId = e.target.value;
                                            const contact = contacts.find((c) => c.id === contactId) || null;
                                            setSelectedContact(contact);
                                        }}
                                        value={selectedContact?.id || ''}
                                    >
                                        <option value="">-- Select --</option>
                                        {contacts.map((contact) => (
                                            <option key={contact.id} value={contact.id}>
                                                {contact.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>


                                {selectedTemplate &&
                                    templates
                                        .find((t) => t.name === selectedTemplate)
                                        ?.placeholders.filter((placeholder) => placeholder !== 'naam' && placeholder !== 'naam_blogger')
                                        .map((placeholder) => (
                                            <div className="input-group" key={placeholder}>
                                                <label>{placeholder.replace(/_/g, ' ')}:</label>
                                                <input
                                                    type="text"
                                                    value={replacements[placeholder] || ''}
                                                    onChange={(e) => setReplacements(prev => ({
                                                        ...prev,
                                                        [placeholder]: e.target.value
                                                    }))}
                                                    placeholder={placeholder.replace(/_/g, ' ')}
                                                />
                                            </div>
                                        ))}

                                <div className="input-group">
                                    <label>Subject:</label>
                                    <input
                                        type="text"
                                        value={emailSubject}
                                        onChange={(e) => setEmailSubject(e.target.value)}
                                        placeholder="Email Subject"
                                    />
                                </div>
                            </div>

                            <div className="email-buttons">
                                <Button onClick={() => login()}>
                                    <img
                                        src={GoogleLogo}
                                        alt="Google Logo"
                                        style={{ width: '20px', marginRight: '8px' }}
                                    />
                                    Sign in With Google
                                </Button>
                                <Button onClick={draftEmail} disabled={!accessToken}>
                                    Draft Email
                                </Button>
                            </div>
                        </Card>
                    </div>
                    : null
                }

                {linkplacement?.id != 'new' && (selectedWebsite !== null || linkplacement?.website !== null) ?
                    <Card style={{ marginBottom: '25px', marginTop: '25px' }}>
                        <DataTable value={linkplacement?.keywords}
                            header={keywordsHeader}
                            className="p-datatable-sm p-datatable-gridlines"
                            onRowEditComplete={(row) => updateCampaignPageLink(row)}
                            showGridlines={true}
                            stripedRows={true}
                            dataKey="id"
                            editMode="row"
                        >
                            <Column body={(row) => linkplacementKeyWordUrlBody(row)} header="Url" field="campaignPageLinks_Id" sortable editor={(row) => dropDownEditor(row, linkOptions)}></Column>
                            <Column field="attribution" header="Attributie" sortable editor={(row => dropDownEditor(row, attributionOptions))}></Column>
                            <Column field="keywordText" header="Keyword tekst" sortable editor={(row => textEditor(row))}></Column>
                            <Column rowEditor headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                            <Column header="" body={linkplacementKeyWordBodyTemplate}></Column>
                        </DataTable>
                    </Card>
                    :
                    null
                }

                <Dialog header={'Linkplacement'} visible={editMode} onHide={() => { setEditMode(false) }} style={{ width: '400px' }}>
                    <EditLinkplacementDialogComponent linkplacement={linkplacement} onHide={() => { setEditMode(false); loadNewLinkplacementAfterChange() }} website={selectedWebsite} />
                </Dialog>


                {/* This confirm-dialog is empty by intent, it's filled by the confirm function. */}
                <ConfirmDialog />
            </div>
        </>
    )
}

export default LinkbuildingLinkplacement;