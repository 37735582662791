/**
 * Copyright 2023 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */

import React from "react";
import { useNavigate } from "react-router-dom";
import { Menubar } from 'primereact/menubar';
import { MenuItem } from 'primereact/menuitem';
import useLinkbuildingCampaignStore from "../../../store/linkbuilding-campaign-store/linkbuilding-campaign-store";

const LinkbuildingMenuBar = () => {

    const navigate = useNavigate();
    
    const campaignStore = useLinkbuildingCampaignStore();
    
    const menuItems: MenuItem[] = [
        { label: 'Home', icon: 'pi pi-fw pi-home', command: () => { navigate('/linkbuilding') } },
        { label: 'Campaigns', icon: 'fa-solid fa-bullhorn', command: () => { navigate('/linkbuilding/campaign') } },
        { label: 'Websites', icon: 'fa-solid fa-desktop', command: () => { navigate('/linkbuilding/websites') } },
    ]
    if(campaignStore.selectedCampaign?.id != null && campaignStore.selectedCampaign?.id != undefined) {
        menuItems.push(
            { label: 'Budgets', icon: 'fa-solid fa-euro', command: () => { navigate('/linkbuilding/budgets') } }
    )
    }

    return (
        <Menubar model={menuItems} />
    )

};

export default LinkbuildingMenuBar;