import React from "react";
import Navbar from "../../../../components/navbar/navbar.component";

const LinkbuildingHome = () => {
    return (
        <div>
            <Navbar activeTool="Linkbuilding" activePage="Home" />
            <div>
                <h1>Linkbuilding Home</h1>
                {/* <p>
                TODO:
                    Alle linkplacements van projecten die open staan op jouw projecten en aangemaakt zijn door jou.
                    Budget overview voor alle campaigns
                </p> */}
            </div>
        </div>
    );
};

export default LinkbuildingHome;
