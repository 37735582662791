import { EnvironmentService } from "../environment/environment.service";
import { ICampaign, ICampaignBudget, ICampaignBudgetOverview, ICampaignLinkplacement, ICampaignPageLink } from "../features/linkbuilding/models/campaign";
import { IChangeLog } from "../features/linkbuilding/models/changelog";
import { IContact, IWebsitePartnerFull } from "../features/linkbuilding/models/contact";
import { LinkPlacementCurrentStatus } from "../features/linkbuilding/models/enums";
import { ILinkPlacement, IWebsite, IWebsiteCRUD, IWebsiteLinkPlacement, ILinkPlacementKeyword, IPartner, IPartnerCRUD, IWebsitePartner } from "../features/linkbuilding/models/linkplacement";
import { ApiResponse } from "./base/api-response";
import { PaginatedResponse } from "./base/paginated-response";

export class LinkbuildingService {

    private readonly baseUrl: string;
    private readonly userName: string;

    constructor() {
        this.baseUrl = new EnvironmentService().getEnvironment().backends.linkbuildingService;
        this.userName = localStorage.getItem('devote_user_name') ?? 'Not known';
    }


    // Campaigns
    async getLinkbuildingCampaigns(projectId: string): Promise<ApiResponse<PaginatedResponse<ICampaign[]>>> {
        const serverResponse = await fetch(`${this.baseUrl}/campaigns`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('devote_access_token')}`,
                'X-Customer-Id': projectId,
                'X-User-Name': this.userName,
            },
        });

        return new ApiResponse<PaginatedResponse<ICampaign[]>>(serverResponse);
    }

    async createCampaign(projectId: string, campaign: ICampaign): Promise<ApiResponse<ICampaign>> {
        const serverResponse = await fetch(`${this.baseUrl}/campaigns`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('devote_access_token')}`,
                'X-Customer-Id': projectId,
                'x-User-Name': this.userName,
            },
            body: JSON.stringify(campaign)
        });
        return new ApiResponse<ICampaign>(serverResponse);
    }

    async updateCampaign(projectId: string, campaign: ICampaign): Promise<ApiResponse<ICampaign>> {
        const serverResponse = await fetch(`${this.baseUrl}/campaigns/${campaign.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('devote_access_token')}`,
                'X-Customer-Id': projectId,
                'X-User-Name': this.userName,
            },
            body: JSON.stringify(campaign)
        });
        return new ApiResponse<ICampaign>(serverResponse);
    }

    // Campaign page links
    async getCampaignPageLinks(projectId: string, campaignId: string): Promise<ApiResponse<PaginatedResponse<ICampaignPageLink[]>>> {
        const serverResponse = await fetch(`${this.baseUrl}/campaigns/${campaignId}/campaignPageLinks`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('devote_access_token')}`,
                'X-Customer-Id': projectId,
                'X-User-Name': this.userName,
            },
        });

        return new ApiResponse<PaginatedResponse<ICampaignPageLink[]>>(serverResponse);
    }

    async createCampaignPageLink(projectId: string, campaignId: string, campaignPageLink: ICampaignPageLink): Promise<ApiResponse<ICampaignPageLink>> {
        const serverResponse = await fetch(`${this.baseUrl}/campaigns/${campaignId}/campaignPageLinks`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('devote_access_token')}`,
                'X-Customer-Id': projectId,
                'X-User-Name': this.userName,
            },
            body: JSON.stringify(campaignPageLink)
        });
        return new ApiResponse<ICampaignPageLink>(serverResponse);
    }

    async updateCampaignPageLink(projectId: string, campaignId: string, campaignPageLink: ICampaignPageLink): Promise<ApiResponse<ICampaignPageLink>> {
        const campaignPageLinkId = campaignPageLink.id;
        console.log(campaignPageLink);
        const serverResponse = await fetch(`${this.baseUrl}/campaigns/${campaignId}/campaignPageLinks/${campaignPageLinkId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('devote_access_token')}`,
                'X-Customer-Id': projectId,
                'X-User-Name': this.userName,
            },
            body: JSON.stringify(campaignPageLink)
        });
        console.log(serverResponse);
        console.log(campaignPageLink);
        return new ApiResponse<ICampaignPageLink>(serverResponse);
    }

    async deleteCampaignPageLink(projectId: string, campaignId: string, campaignPageLinkId?: string): Promise<ApiResponse<void>> {   
        const serverResponse = await fetch(`${this.baseUrl}/campaigns/${campaignId}/campaignPageLinks/${campaignPageLinkId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('devote_access_token')}`,
                'X-Customer-Id': projectId,
                'X-User-Name': this.userName,
            }
        });
        return new ApiResponse<void>(serverResponse);
    }

    async getCampaignLinkplacements(projectId: string, campaignId: string): Promise<ApiResponse<PaginatedResponse<ICampaignLinkplacement[]>>> {
        const serverResponse = await fetch(`${this.baseUrl}/campaigns/${campaignId}/linkplacements`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('devote_access_token')}`,
                'X-Customer-Id': projectId,
                'X-User-Name': this.userName,
            },
        });

        return new ApiResponse<PaginatedResponse<ICampaignLinkplacement[]>>(serverResponse);
    }

    async createCampaignLinkplacement(projectId: string, campaignId: string, campaignLinkplacement: ILinkPlacement): Promise<ApiResponse<ILinkPlacement>> {
        const serverResponse = await fetch(`${this.baseUrl}/campaigns/${campaignId}/linkplacements`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('devote_access_token')}`,
                'X-Customer-Id': projectId,
                'X-User-Name': this.userName,
            },
            body: JSON.stringify(campaignLinkplacement),
        });

        return new ApiResponse<ILinkPlacement>(serverResponse);
    }

    async getCampaignLinkplacement(projectId: string, campaignId: string, linkplacementId: string): Promise<ApiResponse<ILinkPlacement>> {
        const serverResponse = await fetch(`${this.baseUrl}/campaigns/${campaignId}/linkplacements/${linkplacementId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('devote_access_token')}`,
                'X-Customer-Id': projectId,
                'X-User-Name': this.userName,
            },
        });

        return new ApiResponse<ILinkPlacement>(serverResponse);
    }

    async updateCampaignLinkplacement(projectId: string, campaignId: string, campaignLinkplacement: ILinkPlacement): Promise<ApiResponse<ILinkPlacement>> {
        const serverResponse = await fetch(`${this.baseUrl}/campaigns/${campaignId}/linkplacements/${campaignLinkplacement.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('devote_access_token')}`,
                'X-Customer-Id': projectId,
                'X-User-Name': this.userName,
            },
            body: JSON.stringify(campaignLinkplacement),
        });

        return new ApiResponse<ILinkPlacement>(serverResponse);
    }

    async getLinkplacementKeywords(projectId: string, linkplacementId: string): Promise<ApiResponse<PaginatedResponse<ILinkPlacementKeyword[]>>> {
        const serverResponse = await fetch(`${this.baseUrl}/linkplacements/${linkplacementId}/keywords`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('devote_access_token')}`,
                'X-Customer-Id': projectId,
                'X-User-Name': this.userName,
            },
        });
        return new ApiResponse<PaginatedResponse<ILinkPlacementKeyword[]>>(serverResponse);
    }

    async createLinkplacementKeyword(projectId: string, linkplacementId: string, keyword: ILinkPlacementKeyword): Promise<ApiResponse<ILinkPlacementKeyword>> {
        const serverResponse = await fetch(`${this.baseUrl}/linkplacements/${linkplacementId}/keywords`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('devote_access_token')}`,
                'X-Customer-Id': projectId,
                'X-User-Name': this.userName,
            },
            body: JSON.stringify(keyword),
        });
        return new ApiResponse<ILinkPlacementKeyword>(serverResponse);
    }

    async updateLinkplacementKeyword(projectId: string, linkplacementId: string, keyword: ILinkPlacementKeyword): Promise<ApiResponse<ILinkPlacementKeyword>> {
        console.log(keyword);
        const serverResponse = await fetch(`${this.baseUrl}/linkplacements/${linkplacementId}/keywords/${keyword.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('devote_access_token')}`,
                'X-Customer-Id': projectId,
                'X-User-Name': this.userName,
            },
            body: JSON.stringify(keyword),
        });
        return new ApiResponse<ILinkPlacementKeyword>(serverResponse);
    }

    async deleteLinkplacementKeyword(projectId: string, linkplacementId: string, keywordId: string): Promise<ApiResponse<void>> {
        const serverResponse = await fetch(`${this.baseUrl}/linkplacements/${linkplacementId}/keywords/${keywordId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('devote_access_token')}`,
                'X-Customer-Id': projectId,
                'X-User-Name': this.userName,
            },
        });
        return new ApiResponse<void>(serverResponse);
    }

    // General functions
    async getWebsites(partnerId?:string): Promise<ApiResponse<PaginatedResponse<IWebsite[]>>> {
        let url = `${this.baseUrl}/websites`;
        if (partnerId){
            url = `${this.baseUrl}/websites?partnerId=${partnerId}`;
        }

        const serverResponse = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('devote_access_token')}`,
                'X-User-Name': this.userName,
            },
        });

        return new ApiResponse<PaginatedResponse<IWebsite[]>>(serverResponse);
    };

    async getWebsite(projectId: string, websiteId: string): Promise<ApiResponse<IWebsite>> {
        const serverResponse = await fetch(`${this.baseUrl}/websites/${websiteId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('devote_access_token')}`,
                'X-Customer-Id': projectId,
                'X-User-Name': this.userName,
            },
        });
        return new ApiResponse<IWebsite>(serverResponse);
    }

    async getWebsiteLinkplacements(projectId: string, websiteId: string): Promise<ApiResponse<PaginatedResponse<IWebsiteLinkPlacement[]>>> {
        const serverResponse = await fetch(`${this.baseUrl}/websites/${websiteId}/linkplacements`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('devote_access_token')}`,
                'X-Customer-Id': projectId,
                'X-User-Name': this.userName,
            },
        });

        return new ApiResponse<PaginatedResponse<IWebsiteLinkPlacement[]>>(serverResponse);
    }

    async getWebsiteContacts(projectId: string, websiteId: string): Promise<ApiResponse<PaginatedResponse<IContact[]>>> {
        const serverResponse = await fetch(`${this.baseUrl}/websites/${websiteId}/contacts`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('devote_access_token')}`,
                'X-Customer-Id': projectId,
            },
        });

        return new ApiResponse<PaginatedResponse<IContact[]>>(serverResponse);
    }

    async getWebsitePartnersInfo(projectId: string, websiteId: string): Promise<ApiResponse<PaginatedResponse<IWebsitePartnerFull[]>>> {
        const serverResponse = await fetch(`${this.baseUrl}/website-partners/website/${websiteId}/partners`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('devote_access_token')}`,
                'X-Customer-Id': projectId,
            },
        });

        return new ApiResponse<PaginatedResponse<IWebsitePartnerFull[]>>(serverResponse);
    }

    async createWebsite(website: IWebsiteCRUD): Promise<ApiResponse<IWebsiteCRUD>> {
        const serverResponse = await fetch(`${this.baseUrl}/websites`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-User-Name': this.userName,
            },
            body: JSON.stringify(website),
        });
        return new ApiResponse<IWebsiteCRUD>(serverResponse);
    }

    async updateWebsite(websiteId: string, website: IWebsiteCRUD): Promise<ApiResponse<IWebsiteCRUD>> {
        const serverResponse = await fetch(`${this.baseUrl}/websites/${websiteId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'X-User-Name': this.userName,
            },
            body: JSON.stringify(website),
        });
        return new ApiResponse<IWebsiteCRUD>(serverResponse);
    }

    async getChangeLogs(projectId: string, entityId: string): Promise<ApiResponse<PaginatedResponse<IChangeLog[]>>> {
        const serverResponse = await fetch(`${this.baseUrl}/changelogs/${entityId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return new ApiResponse<PaginatedResponse<IChangeLog[]>>(serverResponse);
    };

    async getCampaignBudgets(projectId: string, campaignId: string): Promise<ApiResponse<PaginatedResponse<ICampaignBudget[]>>>{
        const serverResponse = await fetch(`${this.baseUrl}/campaigns/${campaignId}/budgets`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('devote_access_token')}`,
                'X-Customer-Id': projectId,
            },
        });
        
        return new ApiResponse<PaginatedResponse<ICampaignBudget[]>>(serverResponse);
    };

    async getCampaignBudgetsOverview(projectId: string, campaignId: string): Promise<ApiResponse<PaginatedResponse<ICampaignBudgetOverview[]>>>{
        const serverResponse = await fetch(`${this.baseUrl}/campaigns/${campaignId}/budgets/overview`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('devote_access_token')}`,
                'X-Customer-Id': projectId,
            },
        });
        
        return new ApiResponse<PaginatedResponse<ICampaignBudgetOverview[]>>(serverResponse);
    }

    async updateCampaignBudget(projectId: string, campaignId: string, budget: ICampaignBudget): Promise<ApiResponse<ICampaignBudget>>{
        const serverResponse = await fetch(`${this.baseUrl}/campaigns/${campaignId}/budgets/${budget.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('devote_access_token')}`,
                'X-Customer-Id': projectId,
            },
            body: JSON.stringify(budget),
        });
        return new ApiResponse<ICampaignBudget>(serverResponse);
    };

    async createCampaignBudget(projectId: string, campaignId: string, budget: ICampaignBudget): Promise<ApiResponse<ICampaignBudget>>{
        const serverResponse = await fetch(`${this.baseUrl}/campaigns/${campaignId}/budgets`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('devote_access_token')}`,
                'X-Customer-Id': projectId,
            },
            body: JSON.stringify(budget),
        });
        return new ApiResponse<ICampaignBudget>(serverResponse);
    };



    getLinkbuildingStatusText(status:number, publishDate:Date | null):string{
        
        if (status === 5){
            if (publishDate === null) {
                return "Accepted - Pending Publish Date";
            } else if (publishDate < new Date()) {
                return "Accepted - Planned";
            } else if (publishDate >= new Date()) {
                return "Accepted - Published";
            }
        }

        return LinkPlacementCurrentStatus[status] ?? "Unknown Status";

    }


    async getPartners(): Promise<ApiResponse<PaginatedResponse<IPartner[]>>> {
        const serverResponse = await fetch(`${this.baseUrl}/partners`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json', 
            }
        });
        return new ApiResponse<PaginatedResponse<IPartner[]>>(serverResponse);
    }
         
    async createPartner(partner: IPartnerCRUD): Promise<ApiResponse<IPartner>> {
        const serverResponse = await fetch(`${this.baseUrl}/partners`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-User-Name': this.userName,
            },
            body: JSON.stringify(partner)
        });
        return new ApiResponse<IPartner>(serverResponse);
    }

    
    async getPartnerContacts(projectId: string, partnerId: string): Promise<ApiResponse<PaginatedResponse<IContact[]>>> {
        const serverResponse = await fetch(`${this.baseUrl}/partners/${partnerId}/contacts`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('devote_access_token')}`,
                'X-Customer-Id': projectId,
            },
        });

        return new ApiResponse<PaginatedResponse<IContact[]>>(serverResponse);
    }

    async draftEmail(access_token: string, request_body: Object): Promise<Response> {
        const serverResponse = await fetch(`${this.baseUrl}/email/draft`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${access_token}`
            },
            body: JSON.stringify(request_body)
        });
        return serverResponse;
    }

    async getEmailTemplates(): Promise<Response> {
        const serverResponse = await fetch(`${this.baseUrl}/email/templates`, {
            method: 'GET'
        });
        return serverResponse;
    }

}