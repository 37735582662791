import React, { useState } from "react";
import { IWebsite } from "../models/linkplacement";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { Tag } from "primereact/tag";
import { ApiResponse } from "../../../api/base/api-response";
import { PaginatedResponse } from "../../../api/base/paginated-response";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Dialog } from "primereact/dialog";
import WebsiteFormDialogComponent from "./website-form-dialog.component";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { LinkbuildingService } from "../../../api/linkbuilding-service";
import { useSearchParams } from "react-router-dom";
import { BlinkBlur } from "react-loading-indicators";

const websiteListSelectie = ({setWebsiteSelected, rowsPerPageOptionInput, partnerId} ) => {

    const [websites, setWebsites] = React.useState<IWebsite[]>([]);
    const [newWebsiteDialog, setNewWebsiteDialog] = React.useState(false);
    const [websiteLoading, setWebsiteLoading] = React.useState(false);
    const [selectedWebsite, setSelectedWebsite] = React.useState<IWebsite>({} as IWebsite);
    const linkbuildingService = new LinkbuildingService();
    const [searchParams, setSearchParams] = useSearchParams();
    const [rowsPerPageOptions, setRowsPerPageOptions] = useState<number[]>([]);
    const [partnerIdCheck, setPartnerIdCheck] = useState<string>('');


    const updateSelectedWebsite = (website) => {
        setWebsiteSelected(website);
        setSelectedWebsite(website);
    }
    React.useEffect(() => {
        const fetchData = async () => {
            if (searchParams.get('website') != null) {
                await setSelectedWebsite({ id: searchParams.get('website') } as IWebsite);
                // console.log(websites)

                // loadSelectedWebsite({ id: searchParams.get('website') } as IWebsite);
                
            }
        };
        if(rowsPerPageOptionInput == undefined || rowsPerPageOptionInput == null || typeof(rowsPerPageOptionInput) != typeof([])) {
            setRowsPerPageOptions([5, 10, 20]);
        }else{
            setRowsPerPageOptions(rowsPerPageOptionInput);
        }
        fetchData();
        fetchWebsites(partnerId);
    }, [partnerId]);

    const __handleWebsiteResponse = (apiResponse: ApiResponse<PaginatedResponse<IWebsite[]>>) => {
        if (!apiResponse.hasError()) {
            apiResponse.getBody().then((body: PaginatedResponse<IWebsite[]>) => {
                const resp = body.data.map((website) => {
                    website.status = website.status ?? 5;
                    return website;
                });

                setWebsites(resp);
                setCategories([...new Set(resp.map(item => item.category))]);
                setWebsiteLoading(false);
                // if(selectedWebsite != null && selectedWebsite.id != undefined) {
                //     websiteId = selectedWebsite.id;
                // }
                // if (websiteId != undefined) {
                //     updateSelectedWebsite(resp.find((w) => w.id === id));
                //     // let t = resp.find((w) => w.id === id);
                // }
                setWebsiteLoading(false);
            });
        }
    }
    
    const fetchWebsites = async (partnerId?) => {
        setWebsiteLoading(true);
        if(partnerId == undefined) {
            linkbuildingService.getWebsites().then((apiResponse: ApiResponse<PaginatedResponse<IWebsite[]>>) => {
                __handleWebsiteResponse(apiResponse);
            });
        }else{
            linkbuildingService.getWebsites(partnerId).then((apiResponse: ApiResponse<PaginatedResponse<IWebsite[]>>) => {
                __handleWebsiteResponse(apiResponse);
            });
        }
    }

    //WebsiteHeaders
    const websiteHeader = (
        <>
            <div className="table-header">
                <h5 className="mx-0 my-1">Websites</h5>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                </span>
                <Button 
                    icon="pi pi-plus" 
                    className="p-button p-button-success mr-2" 
                    onClick={() => setNewWebsiteDialog(true)} 
                    value="Create a new website" />
            </div>
        </>
    )

    
    const [statuses] = useState([1,2,3,4,5]);
    const [categories, setCategories] = useState(['category1', 'category2', 'category3', 'category4', 'category5']);
    //Filters

    //websiteStatus
    const getSeverityWebsiteStatus = (status) => {
        switch (status) {
            case 1:
                return 'success';

            case 2:
                return 'danger';

            case 3:
                return 'danger';

            case 4:
                return 'warning';
            case 5:
                return 'info';
        }
    };
    const websiteStatusIntToText = (status) => {
        switch (status) {
            case 1: 
                return 'Online';
            case 2:
                return 'Spamy';
            case 3:
                return 'Offline';
            case 4:
                return 'MissingLink';
            case 5:
                return 'unknown';
        }
    }

    const websiteStatusItemTemplate = (option) => {
        let value= option;
        if (option['status'] != undefined) {
            value = option['status'];
        }
        return <Tag value={websiteStatusIntToText(value)} severity={getSeverityWebsiteStatus(value)} />;
    };
    const websiteStatusRowFilterTemplate = (options) => {
        return (
            <Dropdown value={options.value} options={statuses} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={websiteStatusItemTemplate} placeholder="Select One" className="p-column-filter" showClear style={{ minWidth: '12rem' }} />
        );
    };
    //Category filter
    const websiteCategoryItemTemplate = (option) => {
        return <Tag value={option} severity="info" />;
    };
    const websiteCategoryRowFilterTemplate = (options) => {
        return (
            <Dropdown value={options.value} options={categories} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={websiteCategoryItemTemplate} placeholder="Select One" className="p-column-filter" showClear style={{ minWidth: '12rem' }} />
        );
    };
    //DA, TF, Traffic filter
    const websiteMinmumFilterTemplate = (options) => {
        return (  
            <React.Fragment>
                <InputNumber value={options.value ?? null} onChange={(e) => options.filterCallback(e.value)} className="w-full" placeholder="Minimum" />
            </React.Fragment>
        );
    };
    const websiteMaximumFilterTemplate = (options) => {
        return (  
            <React.Fragment>
                <InputNumber value={options.value ?? null} onChange={(e) => options.filterCallback(e.value)} className="w-full" placeholder="Maximum" />
            </React.Fragment>
        );
    };

    const filterMinimum = (value, filter) => {
        if(filter === undefined || filter === null) {
            return true;
        }
        if(value === undefined || value === null) {
            return true;
        }
        if (value >= filter) {
            return true;
        }
        return false;
    }
    const filterMaximum = (value, filter) => {
        if(filter === undefined || filter === null) {
            return true;
        }
        if(value === undefined || value === null) {
            return true;
        }
        if (value >= filter) {
            return true;
        }
        return false;
    }


    return <>
    { websiteLoading && websites.length === 0 ?
        <div>      
            <BlinkBlur color="#d39c68" size="small" text="" textColor="" />
        </div>
    :
        <div>
            { websiteLoading ?
            <div>
                
                <BlinkBlur color="#d39c68" size="small" text="" textColor="" />
            </div>
            : null }
            <DataTable 
                value={websites} 
                paginator 
                rows={rowsPerPageOptions[0]} 
                rowsPerPageOptions={rowsPerPageOptions}
                emptyMessage="No websites found"
                selection={selectedWebsite}
                onSelectionChange={(e) => updateSelectedWebsite(e)}
                header={websiteHeader}
                dataKey="id"
                selectionMode="single" 
            >
                <Column field="domainUrl" header="Domain" sortable filter  />
                <Column field="da" header="Da" sortable filter 
                    showFilterMatchModes={false}
                    filterMatchMode="custom"
                    filterElement={websiteMinmumFilterTemplate} 
                    filterFunction={filterMinimum}
                    />
                <Column field="tf" header="TF" sortable filter 
                    showFilterMatchModes={false}
                    filterMatchMode="custom"
                    filterElement={websiteMinmumFilterTemplate} 
                    filterFunction={filterMinimum}/>
                <Column field="dr" header="Dr" sortable filter 
                    showFilterMatchModes={false}
                    filterMatchMode="custom"
                    filterElement={websiteMinmumFilterTemplate} 
                    filterFunction={filterMinimum}/>
                <Column field="traffic" header="Traffic" sortable filter 
                    showFilterMatchModes={false}
                    filterMatchMode="custom"
                    filterElement={websiteMinmumFilterTemplate} 
                    filterFunction={filterMinimum}/>
                <Column field="category" header="Category" sortable filter 
                    filterElement={websiteCategoryRowFilterTemplate} 
                    showFilterMatchModes={false}/>
                <Column field="status" header="Status" sortable body={websiteStatusItemTemplate} filter
                    filterElement={websiteStatusRowFilterTemplate} 
                    showFilterMatchModes={false}/>
                <Column field="price" header="Last price" sortable filter 
                    showFilterMatchModes={false}
                    filterMatchMode="custom"
                    filterElement={websiteMaximumFilterTemplate} 
                    filterFunction={filterMaximum}/>
            </DataTable>
            
            
        </div>
        }
        
        <Dialog header='Create new website' visible={newWebsiteDialog} onHide={() => { setNewWebsiteDialog(false) }}>
            <WebsiteFormDialogComponent closeDialog={setNewWebsiteDialog} website={null}/>
        </Dialog>
        {/* This confirm-dialog is empty by intent, it's filled by the confirm function. */}
        <ConfirmDialog />
    </>

}

export default websiteListSelectie;